import { template as template_ea9fa80ba6ee444e96cce7e6bc5c0661 } from "@ember/template-compiler";
import icon from "discourse/helpers/d-icon";
const InputTip = template_ea9fa80ba6ee444e96cce7e6bc5c0661(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
