import { template as template_ec28c7f0a7d64b7f8db2819eba5aa287 } from "@ember/template-compiler";
const WelcomeHeader = template_ec28c7f0a7d64b7f8db2819eba5aa287(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
